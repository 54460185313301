<template>
    <div>
        <el-table :data="form" border style="width: 100%">
            <el-table-column prop="privs" label="客户名称" width="150">
                <template slot-scope="scope">
                    <div>{{scope.row.comp_name}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="title" :label="type==1?'手机号':'邮箱'">
                <template slot-scope="scope">
                    <div v-if="scope.row.mobile">{{scope.row.mobile}}</div>
                    <div v-if="scope.row.mail">{{scope.row.mail}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="stat" label="发送状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.stat==0">待发送</div>
                    <div v-if="scope.row.stat==1">已发送</div>
                    <div v-if="scope.row.stat==2">失败</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="flex flex-justify-around" style="padding-top:30px;">
            <div><el-button @click="hide()" style="width: 80px;" type="primary">确定</el-button></div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['form','type'],
        data() {
            return {
                
            }
        },
        watch: {
            
        },
        created() {
            // console.log(this.form);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>
    
</style>