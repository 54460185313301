<template>
    <div style="max-width: 400px;margin: 20px auto 10px;" class="content">
        <div v-if="type==1">{{form}}</div>
        <div v-if="type==2" v-html="form" class="html"></div>
        <div class="flex flex-justify-around" style="padding-top:50px;">
            <div><el-button @click="hide()" style="width: 80px;" type="primary">确定</el-button></div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['form','type'],
        data() {
            return {
                
            }
        },
        // watch:{
        //     form:{
        //         handler() {
        //             this.ruleForm = this.form;
        //         },
        //         deep: true
        //     }
        // },
        created() {
            // console.log(this.form);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>
    .content>>> img,iframe {
        width: 100%;
    }
    img {
        width: 100%;
    }
</style>