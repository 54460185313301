<template>
    <div style="max-width: 450px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="180px" class="login-form">
            <el-form-item prop="sendcloud_tpl_id" label="sendcloud模板id">
                <el-input placeholder="请输入sendcloud模板id" v-model="ruleForm.sendcloud_tpl_id"></el-input>
            </el-form-item>
            <el-form-item prop="name" label="模板名称">
                <el-input placeholder="请输入模板名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <div class="list">
                <div class="list-title flex">
                    <div>模板变量列表：</div>
                    <div class="flex-1">
                        <el-button @click="addStyle()" size="mini" type="primary">添加变量</el-button>
                    </div>
                </div>
                <div v-for="(item,idx) in ruleForm.params" :key="idx" class="list-item flex">
                    <div class="flex-1">
                        <el-form-item :prop="'params.'+ idx +'.var'" :rules="rules.paramsVar" label="变量名">
                            <el-input placeholder="请输入模板变量英文名" v-model="item.var"></el-input>
                        </el-form-item>
                        <el-form-item :prop="'params.'+ idx +'.title'" :rules="rules.paramsTitle" label="变量中文名">
                            <el-input placeholder="请输入模板变量中文名" v-model="item.title"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-button @click="delStyle(idx)" style="margin:5px 0 5px 5px" size="mini" type="primary">删除</el-button>
                    </div>
                </div>
            </div>
            <el-form-item prop="content" label="sendcloud短信模板内容">
                <el-input type="textarea" :rows="3" placeholder="请输入sendcloud短信模板内容" v-model="ruleForm.content"></el-input>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { addMsgTpl } from '@/service/crm';
    export default {
        props: [],
        data() {
            return {
                rules: {
                    sendcloud_tpl_id: [
                        { required: true, message: '请输入sendcloud模板id', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入模板名称', trigger: 'blur' }
                    ],
                    paramsVar: [
                        { required: true, message: '请输入模板变量英文名', trigger: 'blur' }
                    ],
                    paramsTitle: [
                        { required: true, message: '请输入模板变量中文名', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请输入sendcloud短信模板内容', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    sendcloud_tpl_id:null,
                    name:null,
                    params:[
                        {
                            var:null,
                            title:null,
                        }
                    ],
                    content:null,
                },
            }
        },
        // watch:{
        //     form:{
        //         handler() {
        //             this.ruleForm = this.form;
        //         },
        //         deep: true
        //     }
        // },
        created() {
            // this.ruleForm = this.form;
        },
        mounted() {},
        methods: {
            // 添加变量
            addStyle() {
                var obj = {
                    var:null,
                    title:null,
                };
                this.ruleForm.params.push(obj);
                console.log(this.ruleForm.params);
            },
            delStyle(index) {
                this.$confirm('确认是否删除该变量?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.$message({ type: 'success', message: '删除成功!' });
                    this.ruleForm.params.splice(index,1);
                });
            },
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                addMsgTpl(this.ruleForm).then(rst => {
                    this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("新增短信模板成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    .list-title>div:first-child {
        width:180px;
        padding:0 10px 20px 0;text-align:right;
    }
</style>