<template>
    <div style="height:100%">
        <div class="page-header">短信模板</div>
        <div class="block tab flex flex-align-center">
            <div class="tab_menu flex-1">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="">
                <el-button @click="add()" size="small" type="primary">新增模板</el-button>
            </div>
        </div>
        <div style="height: calc(100% - 180px)">
            <div class="block">
                <div v-if="tabIndex==0" class="list" style="height: calc(100% - 60px);overflow-y: auto">
                    <el-table :data="list" style="width: 100%">
                        <el-table-column prop="id" label="ID" width="120">
                        </el-table-column>
                        <el-table-column prop="name" label="模板名称">
                        </el-table-column>
                        <el-table-column prop="params" label="模板变量">
                            <template slot-scope="scope">
                                <div v-if="scope.row.params && scope.row.params.length>0">
                                    <div style="margin-right:5px;" v-for="(item1,idx1) in scope.row.params" :key="idx1">
                                        <span style="margin-right:10px;">{{item1.var}}</span>
                                        <span>{{item1.title}}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column prop="address" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="edit(scope.row,scope.$index)">
                                    <span class="icon iconfont icon-bianji1"></span>&nbsp;编辑
                                </el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>
            </div>
            <SmsLog v-if="tabIndex==1"></SmsLog>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditSms :form="ruleForm" :popTitle="popTitle" @hide="hide"></EditSms>
        </el-dialog>
    </div>
</template>
<script>
    import { listMsgTpl } from '@/service/crm';
    import EditSms from './EditSms.vue';
    import SmsLog from './SmsLog.vue';
    export default {
        components: {
            EditSms,SmsLog
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                tabList:[
                    {name:"短信模板"},
                    {name:"历史发送记录"},
                ],
                tabIndex:0,
                list:[],
                ruleForm:null,
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                listMsgTpl().then(rst => {
                    this.list = rst;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add(item,index) {
                this.popTitle = "新增模板";
                this.popVisible = true;
            },
            edit(item,index) {
                this.popTitle = "修改模板";
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                    name:item.name,
                    stat:item.stat,
                }
            },
            hide() {
                this.popVisible=false;
                this.refreshItems();
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* min-width: 460px; */
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 85px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:30px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>